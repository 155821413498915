exports.components = {
  "component---src-layouts-door-tsx": () => import("./../../../src/layouts/door.tsx" /* webpackChunkName: "component---src-layouts-door-tsx" */),
  "component---src-layouts-post-tsx": () => import("./../../../src/layouts/post.tsx" /* webpackChunkName: "component---src-layouts-post-tsx" */),
  "component---src-layouts-room-tsx": () => import("./../../../src/layouts/room.tsx" /* webpackChunkName: "component---src-layouts-room-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-drzwi-przesuwne-dodatki-tsx": () => import("./../../../src/pages/drzwi-przesuwne/dodatki.tsx" /* webpackChunkName: "component---src-pages-drzwi-przesuwne-dodatki-tsx" */),
  "component---src-pages-drzwi-przesuwne-index-tsx": () => import("./../../../src/pages/drzwi-przesuwne/index.tsx" /* webpackChunkName: "component---src-pages-drzwi-przesuwne-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-pomieszczenia-tsx": () => import("./../../../src/pages/pomieszczenia.tsx" /* webpackChunkName: "component---src-pages-pomieszczenia-tsx" */),
  "component---src-pages-wspolpraca-tsx": () => import("./../../../src/pages/wspolpraca.tsx" /* webpackChunkName: "component---src-pages-wspolpraca-tsx" */)
}

