import { css, createGlobalStyle } from 'styled-components'

import Normalize from 'styles/normalize'

const Global = css`
  * {
    outline-color: ${({ theme }) => theme.colors.pewterblue};
  }

  html {
    font-size: ${({ theme }) => theme.fonts.size};
  }

  body {
    font-family: 'Open Sans', sans-serif;
    color: ${({ theme }) => theme.colors.black};
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Merriweather', sans-serif;
    font-weight: 700;
  }

  img {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
  }

  /* chrome autofill styles fix */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 0s ease-in-out 5000s,
      color 0s ease-in-out 5000s;
  }
`

const GlobalStyles = createGlobalStyle`
    ${Normalize}
    ${Global} 
`

export default GlobalStyles
