import type { Breakpoints, Colors, Container, Fonts } from 'types/theme'

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1600,
}

export const colors: Colors = {
  primary: '#f39c12',
  secondary: '#2980b9',
  danger: '#e74c3c',
  success: '#2ecc71',
  light: '#FEF6E2',
  dark: '#242424',
  text: '#454545',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#6E6E6E',
  lightgray: '#EEEEEE',
  pewterblue: '#8BA6A9',
  jet: '#363638',
  midnightgreen: '#084C61',
  honeydew: '#D0E1D4',
  sonicsilver: '#707070',
  sunray: '#EAB464',
  oldrose: '#C97B7F',
}

export const container: Container = {
  widthNav: '1920px',

  widthXL: '1396px',
  width: '1396px',

  widthSlimXL: '1520px',
  widthSlim: '1100px',

  widthNarrowXL: '820px',
  widthNarrow: '760px',

  paddingMD: '30px',
  padding: '36px',
}

export const fonts: Fonts = {
  size: '16px',
}

const theme = {
  breakpoints,
  colors,
  container,
  fonts,
}

export default theme
